<template>
  <div>
    <b-form @submit.prevent="enviarForm">
      <b-form-row>
        <b-col cols="12" md="3" order="1">
          <label for="iua">IUA: </label>
          <b-form-input
            id="iua"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="IUA"
            v-model="form.iua"
          ></b-form-input>
        </b-col>

       

        <b-col cols="12" md="3" order="2">
          <label for="id_tipo_animal">Tipo animal</label>
          <b-form-select
            id="id_tipo_animal"
            v-model="form.id_tipo_animal"
            :options="mapearTipoAnimal"
            class="mb-3"
            @change="cargarRazas"
          >
          </b-form-select>
        </b-col>
        
         <b-col cols="12" md="3" order="3">
          <label for="id_raza">Tipo raza</label>
          <b-form-select
            id="id_raza"
            v-model="form.id_raza"
            :options="mapearTipoRaza"
            class="mb-3"
            :disabled="tiposRazas.length==0"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" md="3" align-self="end" order-md="4" order="6">
          <b-button
            class="vertical-align mb-3"
            variant="secondary"
            type="submit"
            block
          >
            <b-icon icon="search"></b-icon>
            Consultar
          </b-button>
        </b-col>

        <b-col cols="12" md="3" order-md="5" order="4">
          <label for="nombre">Nombre mascota: </label>
          <b-form-input
            id="nombre"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="nombre"
            v-model="form.nombre"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="3" order-md="6" order="5">
          <label for="identificacion">ID. Responsable: </label>
          <b-form-input
            id="identificacion"
            class="mb-2 mr-sm-2 mb-sm-0"
            placeholder="Identificación"
            v-model="form.identificacion"
          ></b-form-input>
        </b-col>

        <b-col cols="12" md="3" order-md="6" order="5">
           <b-form-checkbox
              id="checkbox-1"
              v-model="form.sin_responsable"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
              class="mt-4"
            >
             Sin <br/>responsable
            </b-form-checkbox>
        </b-col>

        <b-col cols="12" md="3" align-self="end" order="7">
          <b-button class="vertical-align" variant="secondary" block @click="abrirModalAgregar">
            <b-icon icon="plus-circle"></b-icon>
            Agregar
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
    <b-modal
        id="ModalAgregar"
        v-model="mostrarModalAgregar"
        hide-footer
        size="lg"
    >
      <template #modal-header>
        <h5>Agregar Mascota</h5>
        <b-button @click="cancelar(false)" variant="link">
          x
        </b-button>
      </template>
      <div class="d-block text-center p-3">
        <FormularioAgregarAnimal @resetear="cancelar" :animalSeleccionadoAgregar="animalSelec" :vistaVeterinario="true"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
    import { mapActions, mapState } from "vuex";
    import { encontrarError } from '../../../contantesErrores';
    import FormularioAgregarAnimal from './FormularioAgregarAnimal.vue'
    export default {
        name: "FormularioConsultaAnimales",
        components:{
            FormularioAgregarAnimal
        },
        watch: {
            animalSeleccionado: function(newVal, oldVal){
              console.log('desdeFormulario')
                if(newVal!=oldVal && newVal.iua){
                    console.log('true')
                    this.animalSelec=this.animalSeleccionado;
                    this.abrirModalAgregar()
                }else{
                  console.log('false')
                  this.animalSelec=this.animalSeleccionado;
                }
            }
        },
        data() {
            return {
                animalSelec: {},
                mostrarModalAgregar: false,
                cerrarModal: false,
                form: {
                    iua: null,
                    identificacion: null,
                    id_tipo_animal: null,
                    nombre: null,
                    id_raza: null,
                    sin_responsable: 0
                },
            };
        },
        computed: {
            ...mapState("animales", ["tiposAnimales", "tiposRazas", 'animalSeleccionado']),

            mapearTipoAnimal() {
              let mapeadas = []
              this.tiposAnimales.filter((item) => {
                  if(item.descripcion != "Otro"){
                    let animal = {
                      value: item.id,
                      text: item.descripcion,
                    };
                    mapeadas.push(animal)
                  }
              });

              let first = {
                value: null,
                text: 'Seleccione una opción'
              }
              mapeadas.unshift(first)
              return mapeadas;
            },

            mapearTipoRaza() {
            let mapeadas = this.tiposRazas.map((item) => {
                let raza = {
                value: item.id,
                text: item.nombre,
                };
                return raza;
            });
            let first = {
                value: null,
                text: 'Seleccione una opción'
            }
            mapeadas.unshift(first)
            return mapeadas;
            },
        },
        mounted(){
          this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            console.log('modalId de consulta', modalId)
            if(!this.cerrarModal && modalId=="ModalAgregar"){
              bvEvent.preventDefault();
            }else{
              this.cerrarModal=false
            }
          })
          
        },
        methods: {
          ...mapActions('animales', [
            'buscarAnimales', 
            'cargarTiposRazasByTipoAnimal', 
            'resetTipoRaza',
            'resetAnimalSeleccionado',
            'resetAtencionVeterinaria',
            'resetAtencionesVeterinariasTabla'
          ]),

            abrirModalAgregar(){
                this.mostrarModalAgregar = true
            },

            async cargarRazas(id){
              if(id){
                try{
                    await this.cargarTiposRazasByTipoAnimal(id)
                }catch(e){
                    encontrarError(this, e.data)
                }
              }else{
                await this.resetTipoRaza()
              }
            },

            async enviarForm(){
              let filtros = {
                  animalparams: this.form
              }
              try{
                  await this.buscarAnimales(filtros)
              }catch(e){
                  encontrarError(this, e.data)
              }
            },

            cancelar(guardar){
              console.log('aqui cancelar', guardar)
              this.cerrarModalAgregar()
              if(guardar == true){
                this.enviarForm()
              }
            },

            cerrarModalAgregar(){
              console.log('tal vez aqui')
              this.cerrarModal=true
              this.resetAnimalSeleccionado();
              this.resetAtencionVeterinaria();
              this.resetAtencionesVeterinariasTabla();
              this.$bvModal.hide('ModalAgregar')
            }
        },
    };
</script>