<template>
  <div>
    <div class="cajaSuperior">
      <b-row>
        <b-col cols="12" md="3">
          <h2>
            criterios de <br />
            consulta
          </h2>
        </b-col>

        <!-- Formulario consulta -->
        <b-col>
          <div class="contenedorForm pl-5">
            <FormularioConsultaAnimales />
          </div>
        </b-col>
      </b-row>
    </div>
    <br />
    <br />
    <b-row v-if="animalesServicio.length > 0">
      <b-col>
        <b-table
          id="consultaLicencias"
          striped
          responsive
          small
          bordered
          :items="animalesServicio"
          :fields="camposTabla"
          :per-page="perPage"
          :current-page="currentPage"
          :tbody-tr-class="rowClass"
          select-mode="single"
          ref="selectableTable"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(seleccionar)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row v-if="animalesServicio.length > 0">
      
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="consultaLicencias"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import { encontrarError } from '../../contantesErrores';
    import FormularioConsultaAnimales from '../../components/animales/consultaAnimales/FormularioConsultaAnimales.vue';
    import moment from 'moment';
    export default {
        name: 'consultaAnimales',
        components: {
          FormularioConsultaAnimales
        },
        data(){
            return{
                camposTabla: [
                    'nombre_responsable',
                    {
                        key: 'iden_responsable',
                        label: 'Identificación'
                    },
                    {
                        key: 'nombre',
                        label: 'Nombre Mascota'
                    },
                    {
                      key: 'descripcion_tipo_animal',
                      label: 'Tipo Animal'
                    },
                    {
                      key: 'nombre_raza',
                      label: 'Raza'
                    },
                    'color',
                     {
                        key: 'fecha_nacimiento',
                        label: 'Edad',
                        formatter: 'calcularEdad'
                    },
                    {
                        key: 'sexo',
                        formatter: 'mostrarSexo'
                    },
                ],
                perPage: 10,
                currentPage: 1,
            }
        },
          async mounted(){
            try{
              await this.cargarTiposAnimales();
          }catch(e){
              encontrarError(this, e.data)
          }
        },
        computed:{
            ...mapState('animales', ['animalesServicio']),
            rows() {
                return this.animalesServicio.length
            },

            
        },
        methods:{
            ...mapActions('animales', ['cargarAnimales', 'cargarTiposAnimales', 'seleccionarAnimal']),

            mostrarSexo(value){
              if(value==2){
                return 'Hembra'
              }else{
                return 'Macho'
              }
            },

            calcularEdad(value){
                let edad = null
                if(value!=null){
                    let fechaNacimiento = moment(value, 'YYYY-MM-DD')
                    let fechaActual = moment()
                    edad = fechaActual.diff(fechaNacimiento, 'years')
                }
                return edad;
            },

            rowClass(item, type) {
                if (!item || type !== 'row') return
                return 'rowspace'
            },

            onRowSelected(items) {
              if(items.length>0){
                this.seleccionarAnimal(items[0])
              }else{
                this.seleccionarAnimal({})
              }
            },
        }
    }
</script>